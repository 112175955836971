import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ValidationtaskService } from 'src/app/core/services/validationtask/validationtask.service';

@Component({
  selector: 'validationresult',
  templateUrl: './validationresult.component.html',
  styleUrls: ['./validationresult.component.scss']
})
export class ValidationresultComponent implements OnInit, OnChanges {
  @Output() sendCordtoImage: EventEmitter<any> = new EventEmitter<any>();

  @Input() gettextResult: any;
  @Input() getResultData: any;
  @Input() getFieldResultData: any;
  @Input() getResultTables: any;
  @Input() getOtherFieldData: any;
  @Input() getOtherTable: any;
  @Input() getProviderForPlayGround:number;
  @Input() getTranGuid: any;

  @Input() getIndex: any;
  @Input() sendPlayGroundLLMIcon: boolean;
  @Input() getCustomerModelGuid: any | null;
  @Input() verifyLLM: any | null;
  @Input() ValidationTaskStatus: any;

  @Input() getInputText: any;
  @Input() getModelType: any;
  @Input() getModelSubType: any;
  @Output() sendEditedData = new EventEmitter<{ data: any; value: boolean, textAnalysis: boolean }>();
  inputFiledsData = [];
  otherFiledsData = [];

  resultData: any;
  textAnalysis: boolean;

  checked: boolean;
  filteredTable: any = {};
  showPlayGround: boolean;
  propertyName: any;
  onShowMapKeyField: boolean;
  mapKeyData: { keyword: any; customerId: any; };
  removeColum: any;

  isOtherEmptyTable = true;
  isOtherEmptyFields = true;
  isEmptyTable = true;

  isTextExtraction: boolean = false;
  isTextClassification:boolean = false;
  isTextSummarization:boolean = false;
  isTextTranslation:boolean = false;
  textResult: any;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    
  }

  textModelValidation(){
    if(this.getModelType == 4 && this.getModelSubType == 1){
      this.isTextExtraction = true;
      for (const key in this.getFieldResultData) {
        if (this.getFieldResultData.hasOwnProperty(key)) {
          const entry = this.getFieldResultData[key];
          this.inputFiledsData.push({
            propName: key,
            propValue: entry.value,
            propColor: entry.Color,
            propBounds: entry.bounds,
            propValidationRequired: entry.validationRequired
          });
  
        }
      }
    }
    else if(this.getModelType == 4 && (this.getModelSubType == 2 || this.getModelSubType == 3 || this.getModelSubType == 4))
    {
      this.textResult = this.gettextResult.replace(/\\n/g, '\n').replace(/\\t/g, '    ');

    //  this.textResult = this.gettextResult;

  if(this.getModelType == 4 && this.getModelSubType == 2){
      this.isTextClassification = true;
    }
    else if(this.getModelType == 4 && this.getModelSubType == 3){
        this.isTextSummarization = true;
    }
    else if(this.getModelType == 4 && this.getModelSubType == 4){
      this.isTextTranslation = true;
  }
}
   }
  // handlePaste(event: ClipboardEvent) 
  // {event.preventDefault(); 
  // // Prevent the default paste behavior
  // const  text = event.clipboardData?.
  // getData('text/plain') ||''; 
  // // Get the pasted text from the clipboard// Replace tabs and newlines with spaces
  // const  modifiedText = text.replace(/\t|\n/g,' ');    
  // // Insert the modified text into the textarea content
  // const
  // { selectionStart, selectionEnd } = event.target
  // as
  // HTMLTextAreaElement
  // ;     this.textareaContent = [       this.textareaContent.
  // slice
  // (0, selectionStart),       modifiedText,      this.textareaContent.
  // slice
  // (selectionEnd)     ].
  // join
  // (
  // ''
  // );    
  // // Update cursor position after insertion
  // const
  // newPos = selectionStart + modifiedText.length;    
  // setTimeout
  // (
  // () =>
  // {       (event.target
  // as
  // HTMLTextAreaElement
  // ).selectionStart = (event.target
  // as
  // HTMLTextAreaElement
  // ).selectionEnd = newPos; }); }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.getIndex) {
      this.onShowOtherFieldsData();
      this.onShowAllInputs();
      this.onShowAllTables();
      this.textModelValidation();

    }
    
    if (changes.getOtherTable) {
      this.isOtherEmptyTable = this.isEmptyObject(this.getOtherTable);
    }

    if (changes.getOtherFieldData) {
      this.isOtherEmptyFields = this.isEmptyObjectFields(this.getOtherFieldData);
    }
    if (changes.filteredTable) {
      this.isEmptyTable = this.isEmptyNormalTable(this.filteredTable);
    }
  }

  isEmptyNormalTable(obj: any): boolean {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  }
  isEmptyObject(obj: any): boolean {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  isEmptyObjectFields(obj: any): boolean {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  onShowOtherFieldsData() {
    this.otherFiledsData = [];

    for (const key in this.getOtherFieldData) {
      if (this.getOtherFieldData.hasOwnProperty(key)) {
        const entry = this.getOtherFieldData[key];
        this.otherFiledsData.push({
          propName: key,
          propPage:entry.page,
          propValue: entry.value,
          propColor: entry.Color,
          propBounds: entry.bounds,
          propOtherValidationRequired: entry.validationRequired
        });
      }
    }
  }

  onShowAllTables() {
    if (this.getResultTables) {
      this.filteredTable = this.filterByPageTable(this.getResultTables, this.getIndex?.index);
    }
  }

  getHeaders(tableEntries: any[]): string[] {
    // Define the header name you want to exclude
    const excludeHeaderName = this.removeColum;

    // Check if tableEntries is defined and has at least one entry
    if (tableEntries?.length > 0) {
      // Use a Set to store unique keys, excluding the specified header name
      const uniqueKeys = new Set<string>();

      // Iterate over each entry in the tableEntries array
      tableEntries.forEach(entry => {
        // Gather all keys from the current entry
        Object.keys(entry).forEach(key => {
          // Check if the current key is not the one to exclude
          if (key !== excludeHeaderName) {
            // Add each key to the Set of uniqueKeys, excluding the specified header
            uniqueKeys.add(key);
          }
        });
      });

      // Convert the Set back to an array and return it
      return Array.from(uniqueKeys);
    } else {
      // Return an empty array if tableEntries is empty or undefined
      return [];
    }
  }



  filterByPageTable(tables, pageNumber) {
    const filteredTables = {};
    if (tables && typeof tables === 'object') {
      Object.entries(tables).forEach(([tableName, tableData]: [string, any]) => {
        if(pageNumber){
          if (tableData.page === pageNumber) {
            filteredTables[tableName] = tableData;
          }
        }else{
          filteredTables[tableName] = tableData;

        }
       
      });
    }

    return filteredTables;
  }


  onShowAllInputs() {
    console.log(this.otherFiledsData)
    this.inputFiledsData = [];
    if (this.getIndex?.index) {
      if (!this.checked) {
        this.resultData = this.filterResultsByPage(this.getResultData, this.getIndex?.index)
        this.filteredTable = this.filterByPageTable(this.getResultTables, this.getIndex?.index);
        this.otherFiledsData = this.otherFieldFilterData(this.otherFiledsData,this.getIndex?.index)
      } else {
        this.resultData = this.getResultData;
        this.filteredTable = this.filterByPageTable(this.getResultTables, null);
        this.otherFiledsData = this.otherFiledsData;
      }
    }
    for (const key in this.resultData) {
      if (this.resultData.hasOwnProperty(key)) {
        const entry = this.resultData[key];
        this.inputFiledsData.push({
          propName: key,
          propValue: entry.value,
          propColor: entry.Color,
          propBounds: entry.bounds,
          propValidationRequired: entry.validationRequired
        });

      }
    }
  }

  otherFieldFilterData(data: any, pageNumber: number): any {
    const filteredOtherResults = [];

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const entry = data[key];

        if (entry.propPage === pageNumber) {
          filteredOtherResults[key] = entry;
        }
      }
    }
    return filteredOtherResults;
  }

  filterResultsByPage(data: any, pageNumber: number): any {
    const filteredResults = {};

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const entry = data[key];

        if (entry.page === pageNumber) {
          filteredResults[key] = entry;
        }
      }
    }
    return filteredResults;
  }

  addNewRow(tableName: string) {
    this.cdr.detectChanges();
    const defaultFieldStructure = {
      value: "",
      bounds: "",
      Color: "",
      Conf: null,
      page: null,
      validationRequired: false
    };
  
    const table = this.filteredTable[tableName];
    if (table && Array.isArray(table.Value)) {
      const newRow = { isNew: true };
      const headers = table.Value.length > 0 ? Object.keys(table.Value[0]) : ['Sr.No.', 'Description', 'HSN/SAC\nCODE', 'Unit', 'Rate(Rs.)', 'Amount(Rs.)', 'Quantity', 'Price', 'Line Amount'];
  
      headers.forEach(header => {
        newRow[header] = {...defaultFieldStructure};
      });
  
      table.Value.push(newRow);
    }
  }

  onSubmit(value: boolean) {
    Object.keys(this.filteredTable).forEach((key) => {
      if (this.filteredTable[key] && Array.isArray(this.filteredTable[key].Value)) {
        this.filteredTable[key].Value = this.filteredTable[key].Value.map((item: any) => {
          if (item.isNew) {
            delete item.isNew; // Remove the isNew property
          }
          return item;
        });
      }
    });

if(this.isTextClassification == false && this.isTextSummarization == false && this.isTextTranslation == false){
    const data = {
      Tables: this.filteredTable,
      data: this.inputFiledsData,
      otherFileds: this.otherFiledsData,
      othertable: this.getOtherTable
    }
    this.sendEditedData.emit({ data: data, value: value, textAnalysis: false });
  }
  else if(this.isTextSummarization == true || this.isTextTranslation == true || this.isTextClassification == true)
  {
    const data = this.textResult;
    this.sendEditedData.emit({ data: data, value: value, textAnalysis : true });
  }
  }

  onPlayGround(propName) {
    this.propertyName = propName;
    this.showPlayGround = true;
  }

  closePlayground(value: boolean) {
    if (value) {
      this.showPlayGround = false;
    }
  }

  onMapKeyWordField(keyWord, grid, otherTableName) {
    const data: any = {
      grid: grid,
      keyword: keyWord,
      customerId: this.getCustomerModelGuid
    }
    if (data.grid == 'othertable') {
      data.otherTableName = otherTableName
    }
    this.onShowMapKeyField = true;
    this.mapKeyData = data;
  }

  closeMapKey(event) {
    if (event.value) {
      this.onShowMapKeyField = true;
    } else {
      this.onShowMapKeyField = false;
    }
  }

  getSuccesData(event) {
    if (event.grid == 'otherFields') {
      const filterotherTable = this.otherFiledsData.find((res) => res.propName === event.key_word);
      const inputFieldItem = this.inputFiledsData.find((res) => res.propName === event.field_name);
      if (filterotherTable && inputFieldItem) {
        console.log(filterotherTable.propValue, "filterotherTable.propValue");
        inputFieldItem.propValue = filterotherTable.propValue;
      }
    }
  }

  getOtherTableSuccesData(event) {
    const selectedNormalTable = event.table_name;
    const selectedNormalHeaderName = event.field_name;
    const selectedOtherHeaderName = event.keyword;
    const selectedOtherTableName = event.otherTableName;
    const othertable = this.getOtherTable[selectedOtherTableName];
    const otherTableValue = []

    // for (let i = 0; i < othertable.Value.length; i++) {
    //   const currentItem = othertable.Value[i];
    //   otherTableValue.push(currentItem[selectedOtherHeaderName].value);
    // }
    // for (let i = 0; i < otherTableValue.length; i++) {
    //   const firstItem = this.filteredTable[selectedNormalTable].Value[0];
    //   // Create a new object by going through each key in the first item
    //   const newEntry = Object.keys(firstItem).reduce((acc, key) => {
    //     // Initialize each key as an object with default properties
    //     acc[key] = {
    //       "value": "", // Default empty string
    //       "bounds": null,
    //       "Color": null,
    //       "Conf": 0,
    //       "page": 1,
    //       "validationRequired": true
    //     };
    //     return acc;
    //   }, {});

    //   // Ensure the property for selectedNormalHeaderName exists
    //   if (!newEntry[selectedNormalHeaderName]) {
    //     newEntry[selectedNormalHeaderName] = {
    //       "value": "",
    //       "bounds": null,
    //       "Color": null,
    //       "Conf": 0,
    //       "page": 1,
    //       "validationRequired": true
    //     };
    //   }
    //   newEntry[selectedNormalHeaderName].value = otherTableValue[i];
    //   this.filteredTable[selectedNormalTable].Value.push(newEntry);
    // }

    othertable.Value.forEach(item => {
      const itemCopy = JSON.parse(JSON.stringify(item));
      itemCopy[selectedNormalHeaderName] = itemCopy[selectedOtherHeaderName];
      this.filteredTable[selectedNormalTable].Value.push(itemCopy);
      this.removeColum = selectedOtherHeaderName;
    });
  }

  updateValue(rowData: any, header: string, newValue: string) {
    if (!rowData[header]) {
      rowData[header] = { value: newValue, /* other default properties */ };
    } else {
      rowData[header].value = newValue;
    }
  }

  clearHover() {
    this.sendCordtoImage.emit(null);
  }

  onHover(prop) {
    if (prop.hasOwnProperty('bounds'))
    {
      var tmpProp = {
        propName: '',
        propValue: prop['value'],
        propColor: prop['Color'],
        propBounds: prop['bounds'],
        propValidationRequired: prop['validationRequired']
      }
      prop = tmpProp;
    }

    if (prop.hasOwnProperty('propBounds'))
    {
      if (this.validateCoordinates(prop['propBounds']))
      {
        this.sendCordtoImage.emit(prop);
      }
    }
  }

  validateCoordinates(coordString: string): boolean {
    if (coordString == null || coordString == undefined)
      return false;

    const parts = coordString.split(',');
    
    // Check for exactly four parts
    if (parts.length !== 4) {
      return false;
    }
    
    // Check each part is an integer and optionally check for non-negative values
    for (let part of parts) {
      const num = Number(part);
      if (!Number.isInteger(num) || num < 0) {
        return false;
      }
    }
    
    return true;
  }

}
