<p-toast position="top-right"></p-toast>
<div class="row custom-scroll">
  <div class="col-12">
    <div>
      <div *ngIf="isTextClassification || isTextSummarization || isTextExtraction || isTextTranslation">
        <label for="additionalInfo" *ngIf="isTextClassification">Category</label>
        <label for="additionalInfo" *ngIf="isTextSummarization">Summary</label>
        <label for="additionalInfo" *ngIf="isTextExtraction">Extracted Fields</label>
        <label for="additionalInfo" *ngIf="isTextTranslation">Translated Text</label>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col"
              *ngIf="!isTextExtraction && !isTextClassification && !isTextSummarization && !isTextTranslation">
              <div class="col-md-auto">
                All Fields
              </div>
              <div class="col-md-auto">
                <p-inputSwitch [(ngModel)]="checked" (onChange)="onShowAllInputs()"></p-inputSwitch>
              </div>
            </div>
            <div class="col">
              <div class="d-flex justify-content-end" *ngIf="ValidationTaskStatus == 1">
                <button class="btn btn-primary me-1" (click)="onSubmit(true)">Approved</button>
                <button class="btn btn-secondary me-1" (click)="onSubmit(false)">Reject</button>
              </div>
            </div>
          </div>


          <div class="row mb-3" *ngIf="isTextClassification || isTextSummarization || isTextTranslation">
            <div class="col">
              <textarea (paste)="handlePaste($event)" class="form-control" [(ngModel)]="textResult" rows="8"
                class="whiteSpace full_width" [disabled]="ValidationTaskStatus !== 1"></textarea>
            </div>
          </div>

          <div *ngIf="!isTextClassification  && !isTextSummarization && !isTextTranslation">
            <div *ngFor="let inputFiled of inputFiledsData" class="mb-3">
              <span class="colorIcon me-1" [style.background-color]="inputFiled.propColor"></span>
              <label for="{{inputFiled.propName}}" (mouseenter)="onHover(inputFiled)" (mouseleave)="clearHover()">
                {{inputFiled.propName}}</label>
              <span class="input-icon" pTooltip="Play Ground" tooltipPosition="top"
                *ngIf="verifyLLM == 'LLM' && !(isTextExtraction || isTextClassification || isTextSummarization || isTextTranslation)"
                (click)="onPlayGround(inputFiled.propName)"><i
                  class="mdi mdi-presentation-play font-size-15 align-middle"></i></span>

              <div class="input-icon-wrapper" (mouseenter)="onHover(inputFiled)" (mouseleave)="clearHover()">
                <input *ngIf="!isTextExtraction && !isTextClassification && !isTextSummarization && !isTextTranslation"
                  type="text" pInputText id="{{inputFiled.propName}}" name="propName"
                  aria-describedby="{{inputFiled.propName}}-help" [(ngModel)]="inputFiled.propValue"
                  class="form-control" [ngClass]="{'border-red': inputFiled.propValidationRequired}" />

                <textarea *ngIf="isTextExtraction" class="form-control" pInputText id="{{inputFiled.propName}}"
                  name="propName" aria-describedby="{{inputFiled.propName}}-help" [(ngModel)]="inputFiled.propValue"
                  [ngClass]="{'border-red': inputFiled.propValidationRequired}"></textarea>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="card"
      *ngIf="filteredTable && objectKeys(filteredTable).length > 0  && !isTextClassification && !isTextSummarization && !isTextTranslation">
      <div class="card-body">
        <div class="row mb-3">
          <div *ngFor="let tableEntry of filteredTable | keyvalue">
            <h6>{{ tableEntry.key }}<span class="input-icon" pTooltip="Append Data" tooltipPosition="top"><i
                  class="mdi mdi-play-circle font-size-15 align-middle"
                  (click)="onMapKeyWordField(tableEntry.key,'table')"></i></span>
            </h6>
            <p-table [value]="tableEntry.value.Value">
              <ng-template pTemplate="header">
                <tr>
                  <th *ngFor="let header of getHeaders(tableEntry.value?.Value)">{{ header }}</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
                <tr>
                  <td (mouseenter)="onHover(rowData[header])" (mouseleave)="clearHover()"
                    *ngFor="let header of getHeaders(tableEntry.value?.Value)" pEditableColumn>
                    <ng-container *ngIf="rowData.isNew">
                      <!-- Input field for new row -->
                      <input *ngIf="rowData[header]" [(ngModel)]="rowData[header].value"
                        (ngModelChange)="rowData[header].value = $event" name="{{header}}" type="text"
                        class="widthfullpersent" required autofocus>

                    </ng-container>
                    <ng-container *ngIf="!rowData.isNew">
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <input [ngModel]="rowData[header]?.value"
                            (ngModelChange)="updateValue(rowData, header, $event)" type="text" required autofocus
                            [ngClass]="{'border-red': rowData[header]?.validationRequired}">

                        </ng-template>
                        <ng-template pTemplate="output">
                          <span [ngClass]="{'border-red': rowData[header]?.validationRequired}">
                            {{ rowData[header]?.value }}
                          </span>
                        </ng-template>
                      </p-cellEditor>
                    </ng-container>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer" *ngIf="ValidationTaskStatus == 1">
                <tr>
                  <td [attr.colspan]="getHeaders(tableEntry.value?.Value).length">
                    <button class="btn btn-soft-primary" (click)="addNewRow(tableEntry.key)">Add
                      Row</button>
                  </td>
                </tr>
              </ng-template>
            </p-table>


            <div class="mb-3"></div>

          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="isTextClassification == false && isTextSummarization == false && isTextTranslation == false">
      <div class="card" *ngIf="!isOtherEmptyFields || !isOtherEmptyTable">
        <div class="card-body">
          <div class="row mb-3">
            <p-accordion>
              <p-accordionTab header="Other Fields" *ngIf="!isOtherEmptyFields">
                <div *ngFor="let otherFiled of otherFiledsData" class="mb-3">
                  <span class="colorIcon me-1" [style.background-color]="otherFiled?.propColor"></span>

                  <label for="{{otherFiled.propName}}" (mouseenter)="onHover(otherFiled)" (mouseleave)="clearHover()">
                    {{otherFiled.propName}}</label>
                  <span class="input-icon" pTooltip="Map" tooltipPosition="top"><i
                      class="mdi mdi-play-circle font-size-15 align-middle"
                      (click)="onMapKeyWordField(otherFiled.propName,'otherFields')"></i></span>
                  <div class="input-icon-wrapper">
                    <input type="text" pInputText id="{{otherFiled.propName}}" name="propName"
                      aria-describedby="{{otherFiled.propName}}-help" [(ngModel)]="otherFiled.propValue"
                      class="form-control" [ngClass]="{'border-red': otherFiled.propOtherValidationRequired}"
                      (mouseenter)="onHover(otherFiled)" (mouseleave)="clearHover()" />
                  </div>
                </div>
              </p-accordionTab>
              <p-accordionTab header="Other Table" *ngIf="!isOtherEmptyTable">
                <div *ngFor="let tableEntry of getOtherTable | keyvalue">
                  <h6>{{ tableEntry.key }} </h6>
                  <p-table [value]="tableEntry.value.Value">
                    <ng-template pTemplate="header">
                      <tr>
                        <th *ngFor="let header of getHeaders(tableEntry.value.Value)">{{ header }} <span
                            class="input-icon" pTooltip="Append Data" tooltipPosition="top"><i
                              class="mdi mdi-play-circle font-size-15 align-middle"
                              (click)="onMapKeyWordField(header,'othertable',tableEntry.key)"></i></span></th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
                      <tr>
                        <td (mouseenter)="onHover(rowData[header])" (mouseleave)="clearHover()"
                          *ngFor="let header of getHeaders(tableEntry.value?.Value)" pEditableColumn>
                          <ng-container *ngIf="rowData.isNew">
                            <!-- Input field for new row -->
                            <input name="header" [(ngModel)]="rowData[header].value" type="text"
                              class="widthfullpersent" required autofocus>
                          </ng-container>
                          <ng-container *ngIf="!rowData.isNew">
                            <!-- Display data for existing row -->
                            <p-cellEditor>
                              <ng-template pTemplate="input">
                                <input [(ngModel)]="rowData[header].value" name="rowdata" type="text"
                                  class="widthfullpersent" required autofocus
                                  [ngClass]="{'border-red': rowData[header]?.validationRequired}">
                              </ng-template>
                              <ng-template pTemplate="output">
                                <span [ngClass]="{'border-red': rowData[header]?.validationRequired}">
                                  {{ rowData[header]?.value }}
                                </span>
                              </ng-template>
                            </p-cellEditor>
                          </ng-container>
                        </td>
                      </tr>
                    </ng-template>

                  </p-table>


                  <div class="mb-3"></div>

                </div>
              </p-accordionTab>
            </p-accordion>

          </div>
        </div>
      </div>
    </div>
   
  </div>
 
</div>
@if(!(isTextClassification || isTextSummarization || isTextTranslation) && !modelIsFileDeleted){
  <div class="group-button mt-2">
    <button class="btn btn-soft-primary" (click)="onAddFeedback()">Add to FeedBack</button>
  </div>
  }

<p-sidebar [(visible)]="showPlayGround" styleClass="responsive_sidebar widthfifty" appendTo="body" position="right">
  <playground *ngIf="showPlayGround" [getPropertyName]="propertyName"
    [onProviderForPlayGround]="getProviderForPlayGround" [sendCustomerModelGuid]="getCustomerModelGuid"
    (closeSidebar)="closePlayground($event)" [tranGuid]="getTranGuid">
  </playground>
</p-sidebar>

<p-sidebar [(visible)]="onShowMapKeyField" styleClass="responsive_sidebar widthfifty" appendTo="body" position="right">
  <mapkey *ngIf="onShowMapKeyField" [getMapKeyData]="mapKeyData" (closeSidebar)="closeMapKey($event)"
    (sendSuccesData)="getSuccesData($event)" (sendOtherTableSuccesData)="getOtherTableSuccesData($event)"></mapkey>
</p-sidebar>