<p-toast position="top-right"></p-toast>
<div class="w-100 user-chat mt-4 mt-sm-0 ms-lg-1">
    <div class="card">
        <div class="p-3 px-lg-4 border-bottom">
            <!-- Chat header -->
            <div class="row">
                <div class="col-xl-4 col-7">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <h3 class=" mb-1 text-truncate">{{getPropertyName}}</h3>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-8 col-5">
                    <p-fileUpload mode="basic" chooseLabel="Choose" name="demo[]" accept="image/*,application/pdf" maxFileSize="1000000"
                        (onSelect)="onUpload($event)"></p-fileUpload>
                </div> -->
            </div>
        </div>

        <!-- Chat messages -->
        <div class="chat-conversation p-3 px-2" class="mesgCl">
            <ul class="list-unstyled mb-0">
                <li *ngFor="let message of messages">
                    <div class="conversation-list"
                        [ngClass]="{'right': message.sender === 'user', 'left': message.sender === 'bot'}">
                        <div class="ctext-wrap d-flex align-items-center">
                          <p-radioButton class="me-2" *ngIf="message.sender === 'user'" name="user"
                              [value]="message.text" [(ngModel)]="messageSelected" inputId="ingredient1">
                          </p-radioButton>
                          <div class="ctext-wrap-content">
                              <p class="mb-0">{{ message.text }}</p>
                          </div>
                      </div>
                      
                    </div>
                </li>

            </ul>
        </div>
        <!-- Chat input -->
        <div class="p-3 border-top">
            <div class="row">
                <div class="col">
                    <input type="text" class="form-control chat-input rounded" placeholder="Enter message..."
                        [(ngModel)]="inputText" (keyup.enter)="sendMessage()" />
                </div>
                <div class="col-auto">
                    <button type="button" class="btn btn-soft-primary me-2" (click)="sendMessage()">
                        <span class="d-none d-sm-inline-block me-2">Send</span>
                        <i class="mdi mdi-send float-end"></i>
                    </button>
                    <button (click)="onCheckboxChange()" class="me-2 btn-soft-primary btn" [disabled]="!messageSelected">Use
                        this</button>
                </div>
            </div>
        </div>

    </div>
</div>

<p-sidebar [(visible)]="showConfirmDocuments" styleClass="responsive_sidebar widthtwetyfive" appendTo="body" position="right"
 >
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-xl-12 col-md-12">
              <div class="form-group mb-3">
                <p-checkbox [(ngModel)]="checkedValue" [binary]="true" 
                [ngModelOptions]="{standalone: true}"  label="Apply this for all the documents?" [value]="true"  (onChange)="onCheckConfirm($event)"></p-checkbox>
              </div>
            </div>
            <div class="col-xl-12 col-md-12" *ngIf="!checkedValue">
              <div class="form-group mb-3">
                <input type="text" class="form-control" [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="classificationKeywords" placeholder="Classification Keywords">
              </div>
            </div>
            <div class="col-xl-12 col-md-12">
              <div class="form-group mb-3">
                <button type="button" class="btn btn-soft-primary" (click)="apiCall()" >
                  Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-sidebar>